import { IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import Colors from '../styles/colors';
import content from '../content/header.json';
import { Link } from 'gatsby';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import Dimensions from '../styles/dimensions';
import Menu from '../images/menu';
import CloseIcon from '../images/closeIcon';
import Navigation from './navigation';
// @ts-ignore
import LogoImg from '../images/Easyfit_logo.svg';

interface ClassProps {
	navigationOpen: boolean;
}

const Header = () => {
	const [navigationOpen, setNavigationOpen] = React.useState(false);

	const classes = useClasses({ navigationOpen });

	const handleNavigationClick = React.useCallback(() => {
		setNavigationOpen(!navigationOpen);
	}, [navigationOpen]);

	React.useEffect(() => {
		let container: HTMLElement;
		if (typeof window !== 'undefined') {
			// @ts-ignore
			container = document.getElementById('___gatsby');

			if (navigationOpen) {
				container.style.overflow = 'hidden';
			} else {
				container.style.overflow = 'auto';
			}
		}
		return () => {
			container.style.overflow = 'auto';
		};
	}, [navigationOpen]);

	const handleLinkClick = React.useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (typeof window !== 'undefined') {
			const container = document.getElementById('___gatsby');

			// @ts-ignore
			if (event.target.nodeName === 'A' && window.location.pathname === event.target.getAttribute('href')) {
				container?.scroll({ top: 0, behavior: 'smooth' });
			} else if (
				// @ts-ignore
				event.target.nodeName !== 'A' &&
				// @ts-ignore
				window.location.pathname === event.target.parentNode.getAttribute('href')
			) {
				container?.scroll({ top: 0, behavior: 'smooth' });
			} else {
				container?.scroll({ top: 0, behavior: 'auto' });
			}
		}
	}, []);

	return (
		<header className={classes.header}>
			<div className={classes.container}>
				<Link to="/" className={classes.logo} onClick={handleLinkClick}>
					<img src={LogoImg} alt={content.logo.alt} loading="lazy" />
				</Link>

				<nav className={classes.navigation}>
					<Link
						to={content.navigation.careers.src}
						className={classes.secondaryLink}
						onClick={handleLinkClick}>
						<span className={classes.navigationItem}>{content.navigation.careers.text}</span>
					</Link>

					<Link
						to={content.navigation.appointment.src}
						className={`${classes.contactLink} ${classes.secondaryLink}`}
						onClick={handleLinkClick}>
						<span className={classes.navigationItem}>{content.navigation.appointment.text}</span>
					</Link>

					<Link
						to={content.navigation.becomeMember.src}
						className={`${classes.contactLink} ${classes.secondaryLink}`}
						onClick={handleLinkClick}>
						<span className={classes.navigationItem}>{content.navigation.becomeMember.text}</span>
					</Link>

					<Link to={content.navigation.offer.src} className={classes.offerLink} onClick={handleLinkClick}>
						<div className={classes.offerContainer}>
							<span className={classes.offerTextPart1}>{content.navigation.offer.textPart1}</span>
							<span>{content.navigation.offer.textPart2}</span>
						</div>
					</Link>

					<IconButton
						children={navigationOpen ? <CloseIcon /> : <Menu />}
						classes={{ root: classes.menuButton }}
						onClick={handleNavigationClick}
						disableRipple
					/>
				</nav>
			</div>
			<Navigation navigationOpen={navigationOpen} content={content.navigation} />
		</header>
	);
};

export default Header;

const useClasses = makeStyles({
	header: {
		position: 'sticky',
		zIndex: 1000,
		height: '6.25em',
		top: 0,
		left: 0,
		width: '100%',
		backgroundColor: Colors.white,
	},
	container: {
		paddingLeft: '2.75em',
		paddingRight: '1.875em',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		position: 'relative',
		top: '3.125em',
		transform: 'translateY(-50%)',
		'@media (max-width: 959px)': {
			paddingLeft: '2.25em',
			paddingRight: '1.5em',
		},
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '0.25em',
		},
	},
	navigation: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	navigationItem: {
		fontFamily: FiraSans.semiBold,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.textBlack,
	},
	offerLink: {
		opacity: (props: ClassProps) => (props.navigationOpen ? 0 : 1),
		pointerEvents: (props: ClassProps) => (props.navigationOpen ? 'none' : 'auto'),
		transition: (props: ClassProps) =>
			props.navigationOpen ? 'opacity 0.35s linear' : 'opacity 0.35s linear 0.35s',
		'@media (max-width: 599px)': {
			display: 'none',
		},
	},
	offerContainer: {
		fontFamily: FiraSans.semiBold,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.white,
		backgroundColor: Colors.limeGreen,
		borderRadius: '4px',
		padding: '0.625em 1em',
		marginLeft: '2.5em',
		'@media (max-width: 599px)': {
			marginLeft: 0,
		},
	},
	offerTextPart1: {
		'@media (max-width: 599px)': {
			display: 'none',
		},
	},
	contactLink: {
		marginLeft: '3.5em',
	},
	logo: {
		width: '11.25em',
	},
	secondaryLink: {
		'@media (max-width: 959px)': {
			display: 'none',
		},
	},
	menuButton: {
		marginLeft: '1.5em',
		width: '61px',
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'@media (min-width: 960px)': {
			display: 'none',
		},
		'@media (max-width: 599px)': {
			marginLeft: '0.875em',
		},
	},
});
