import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import React from 'react';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import content from '../content/cookieModal.json';
import Cookies from 'js-cookie';
import Fjalla from '../fonts/Fjalla_One/fjalla';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import Dimensions from '../styles/dimensions';
import Colors from '../styles/colors';

interface Props {
	areCookiesAccepted: boolean;
	setAreCookiesAccepted: (value: boolean) => void;
}

const CookieModal = (props: Props, ref: any) => {
	const { areCookiesAccepted, setAreCookiesAccepted } = props;

	const [open, setOpen] = React.useState(false);

	const location = useLocation();

	const classes = useClasses();

	const openDialog = React.useCallback(() => {
		setOpen(true);
	}, []);

	const closeDialog = React.useCallback(() => {
		setOpen(false);
	}, []);

	React.useImperativeHandle(ref, () => {
		return {
			openDialog: openDialog,
		};
	});

	const handleReject = React.useCallback(() => {
		setAreCookiesAccepted(true);
		Cookies.set('gatsby-gdpr-google-analytics', 'false');
		Cookies.set('gatsby-gdpr-google-tagmanager', 'false');
		Cookies.set('gatsby-gdpr-facebook-pixel', 'false');
		closeDialog();
	}, []);

	const handleAccept = React.useCallback(() => {
		Cookies.set('gatsby-gdpr-google-analytics', 'true');
		Cookies.set('gatsby-gdpr-google-tagmanager', 'true');
		Cookies.set('gatsby-gdpr-facebook-pixel', 'true');
		initializeAndTrack(location);
		closeDialog();
	}, [location]);

	return (
		<Dialog open={open && !areCookiesAccepted} onClose={closeDialog} disableBackdropClick maxWidth={'md'}>
			<DialogTitle classes={{ root: classes.dialogTitle }}>{content.title}</DialogTitle>
			<DialogContent classes={{ root: classes.dialogContent }}>{content.description}</DialogContent>
			<DialogActions classes={{ root: classes.dialogActions, spacing: classes.dialogActionsSpacing }}>
				<a href={content.privacy.href} target="_blank" className={classes.privacyLink}>
					{content.privacy.text}
				</a>
				<button onClick={handleReject} className={classes.rejectButton}>
					{content.reject}
				</button>
				<button onClick={handleAccept} className={classes.acceptButton}>
					{content.accept}
				</button>
			</DialogActions>
		</Dialog>
	);
};

export default React.forwardRef(CookieModal);

const useClasses = makeStyles({
	dialogTitle: {
		padding: '1em 2em',
		'& h2': {
			fontFamily: Fjalla.regular,
			fontSize: Dimensions.regularText.fontSize,
			lineHeight: Dimensions.regularText.lineHeight,
			color: Colors.textBlack,
		},
	},
	dialogContent: {
		padding: '1em 2em',
		fontFamily: FiraSans.regular,
	},
	dialogActions: {
		padding: '1em 2em',
		alignItems: 'flex-end',
		'@media (max-width: 649px)': {
			flexDirection: 'column',
		},
		'&>*:not(:last-child)': {
			'@media (max-width: 649px)': {
				marginBottom: '0.66666667em',
			},
		},
	},
	dialogActionsSpacing: {
		'&>:not(:first-child)': {
			marginLeft: '1rem',
			'@media (max-width: 649px)': {
				marginLeft: 0,
			},
		},
	},
	acceptButton: {
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		backgroundColor: Colors.limeGreen,
		color: Colors.white,
		border: 0,
		borderRadius: '4px',
		padding: '0.75em 2.25em',
		outline: 0,
		cursor: 'pointer',
	},
	rejectButton: {
		textDecoration: 'underline',
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		color: Colors.textGray,
		border: 0,
		outline: 0,
		cursor: 'pointer',
		backgroundColor: 'unset',
		padding: 0,
	},
	privacyLink: {
		textDecoration: 'underline',
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		color: Colors.textGray,
	},
});
