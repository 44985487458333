import { makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import React from 'react';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import Colors from '../styles/colors';
import Dimensions from '../styles/dimensions';

interface Content {
	careers: {
		text: string;
		src: string;
	};
	appointment: {
		text: string;
		src: string;
	};
	becomeMember: {
		text: string;
		src: string;
	};
	offer: {
		textPart1: string;
		textPart2: string;
		src: string;
	};
}

interface Props {
	navigationOpen: boolean;
	content: Content;
}

interface ClassProps {
	navigationOpen: boolean;
}

const Navigation = (props: Props) => {
	const { navigationOpen, content } = props;

	const classes = useClasses({ navigationOpen });

	return (
		<div className={classes.container}>
			<nav className={classes.navigation}>
				<Link to={content.careers.src} className={classes.link}>
					{content.careers.text}
				</Link>
				<Link to={content.appointment.src} className={classes.link}>
					{content.appointment.text}
				</Link>
				<Link to={content.becomeMember.src} className={classes.link}>
					{content.becomeMember.text}
				</Link>

				<hr className={classes.divider} />

				<Link to={content.offer.src} className={classes.offerLink}>
					{content.offer.textPart1}
					{content.offer.textPart2}
				</Link>
			</nav>
		</div>
	);
};

export default Navigation;

const useClasses = makeStyles({
	container: (props: ClassProps) => ({
		position: 'sticky',
		zIndex: 1000,
		top: '6.25em',
		height: props.navigationOpen ? 'calc(100vh - 5.9em)' : 0,
		left: 0,
		right: 0,
		transition: props.navigationOpen ? 'height 0.35s ease-in' : 'height 0.25s ease-in 0.35s',
		backgroundColor: Colors.white,
	}),
	navigation: (props: ClassProps) => ({
		paddingLeft: '1em',
		paddingRight: '1em',
		pointerEvents: props.navigationOpen ? 'auto' : 'none',
		opacity: props.navigationOpen ? 1 : 0,
		transition: props.navigationOpen ? 'opacity 0.35s ease-in 0.35s' : 'opacity 0.35s ease-in',
		position: 'relative',
		top: '2.25em',
		'@media(min-width: 600px)': {
			paddingLeft: '2.25em',
			paddingRight: '2.25em',
		},
	}),
	link: {
		display: 'block',
		fontFamily: FiraSans.semiBold,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.textBlack,
		margin: '2.25em 0',
	},
	divider: {
		border: 0,
		borderTop: `1px solid ${Colors.dividerGray}`,
		marginBottom: 'calc(2em + 8px)',
	},
	offerLink: {
		fontFamily: FiraSans.semiBold,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.white,
		padding: '0.625em 1em',
		borderRadius: '4px',
		backgroundColor: Colors.limeGreen,
	},
});
