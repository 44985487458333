import React from 'react';

const CloseIcon = () => (
	<svg
		width="22px"
		height="22px"
		viewBox="0 0 22 22"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink">
		<g id="Tablet-&amp;-mobile" stroke="none" strokeWidth="1" fill="#000" fillRule="evenodd">
			<g id="Menu---Tablet" transform="translate(-710.000000, -39.000000)" fill="#000" fillRule="nonzero">
				<g id="close_icon" transform="translate(710.000000, 39.000000)">
					<polygon
						id="Path"
						points="21.5 2.62 19.38 0.5 11 8.88 2.62 0.5 0.5 2.62 8.88 11 0.5 19.38 2.62 21.5 11 13.12 19.38 21.5 21.5 19.38 13.12 11"></polygon>
				</g>
			</g>
		</g>
	</svg>
);

export default CloseIcon;
